import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { User } from '../../../shared/shared.models';
import { environment } from '../../../environments/environments';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  private user: User = new User();

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private routes: Router
  ) {
    this.oidcSecurityService.userData$.subscribe((val) => {
      this.user = val.userData;
      if (this.user) {
        const notApplyTwoFactorValidation = this.user?.TwoFactorEnabled === '0' || !this.user?.TwoFactorEnabled;
        if (notApplyTwoFactorValidation) {
          alert('請先至登入管理平台啟動兩步驗證');
          window.location.href = environment.SSOLoginUrl;
        }
        if (Array.isArray(this.user.role)) {
          if (!this.user.role.some(i => i.includes('report') || i === 'Admin')) {
            alert('您無權限登入此系統，請洽詢IT。');
            window.location.href = environment.SSOLoginUrl;
          }
        }
        else {
          if (this.user.role !== 'Admin' && !this.user.role.includes('report')) {
            alert('您無權限登入此系統，請洽詢IT。');
            window.location.href = environment.SSOLoginUrl;
          }
        }
      }
    });
  }

  public handleCallback(): void {
    this.oidcSecurityService.checkAuth()
      .subscribe((isAuthenticated) => {
        if (!isAuthenticated.isAuthenticated) {
          this.oidcSecurityService.authorize();
        } else {
          if (window.location.href.includes('/?code')) {
            this.routes.navigate(['']);
          }
        }
      });
  }
  /**
   * Returns the current user
   */
  public currentUser(): User {
    return this.user;
  }

  public isAuthToViewReport(): boolean {
    if (Array.isArray(this.user.role)) {
      if (!this.user.role.some(i => i === 'report.view' || i === 'Admin')) {
        return false;
      } else {
        return true;
      }
    }
    else {
      if (this.user.role !== 'Admin' && this.user.role !== 'report.view') {
        return false;
      } else {
        return true;
      }
    }
  }

  public isAuthToUseData(): boolean {
    if (Array.isArray(this.user.role)) {
      if (this.user.role.some(i => (i.includes('report') && i !== 'report.view') || i === 'Admin')) {
      return true;
      } else {
      return false;
      }
    } else {
      if ((this.user.role.includes('report') && this.user.role !== 'report.view') || this.user.role === 'Admin') {
      return true;
      } else {
      return false;
      }
    }
  }


  /**
   * Returns the current user roles
   */
  // public roles(): string[] {
  //   return Array.isArray(this.user.role) ? this.user.role : [this.user.role];
  // }

  /**
   * Returns the current is admin role
   */
  // public isAdmin(): boolean {
  //   return this.roles().includes('Admin') || false;
  // }

  /**
   * Returns the current is only storeBranch role
   */
  // public isStoreBranches(): boolean {
  //   return this.user.email.includes('s7') && this.roles().includes('store.branch') || false;
  // }


  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login() {
    this.oidcSecurityService.authorize();
    // return getFirebaseBackend().loginUser(email, password).then((response: any) => {
    //     const user = response;
    //     return user;
    // });
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {
    // return getFirebaseBackend().registerUser(email, password).then((response: any) => {
    //     const user = response;
    //     return user;
    // });
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    // return getFirebaseBackend().forgetPassword(email).then((response: any) => {
    //     const message = response.data;
    //     return message;
    // });
  }

  /**
   * Logout the user
   */
  logout() {
    this.oidcSecurityService.logoff();
    // logout the user
    // getFirebaseBackend().logout();
  }
}

