
/**
 * 通路
 *
 * @export
 * @interface Places
 */
export class Places {
  placeCode: string;
  placeName: string;
  placeGroupName: string;
  isSelected?: boolean;
  active?: boolean;
  isToggle?: boolean;
}

/**
 * 產品
 *
 * @export
 * @interface Products
 */
export class Products{
  name: string;
  code: string;
  isSelected?: boolean;
}

/**
 * 基本通路群組
 *
 * @export
 * @class BasePlaceGroups
 */
export class BasePlaceGroups {
  placeGroupCode: string;
  placeGroupName: string;
}

/**
 * 基本產品群組
 *
 * @export
 * @class BaseProductGroups
 */
export class BaseProductGroups {
  productGroupCode: string;
  productGroupName: string;
}


/**
 * 基本通路群組(擴充取得通路資料)
 *
 * @export
 * @class PlaceGroupsWithPlaces
 */

export class PlaceGroupsWithPlaces extends BasePlaceGroups {
  placeVMs: Places[];
}

/**
 * 基本通路群組(擴充取得銷售YOY)
 *
 * @export
 * @class PlaceGroupsWithRevenueGrowth
 */
export class PlaceGroupsWithRevenueGrowth extends BasePlaceGroups {
  datas: RevenueGrowthPerformance[];
}

/**
 * 基本產品群組(擴充取得銷售YOY)
 *
 * @export
 * @class ProductGroupsWithRevenueGrowth
 */
export class ProductGroupsWithRevenueGrowth extends BaseProductGroups {
  datas: RevenueGrowthPerformance[];
}

/**
 * 業績群組
 *
 * @export
 * @class PerformanceGroup
 */
export class PerformanceGroup {
  constructor() {
    this.performance = new TotalSalesPerformance();
  }
  department: string;
  performance: TotalSalesPerformance;
  details: TotalSalesPerformance[];
  detailsTotalSalesAmount?: number;
  isAllSelected?: boolean;
}

/**
 * 銷售YOY列表
 *
 * @export
 * @class RevenueGrowthList
 */
export class RevenueGrowthList {
  rowName: string;
  currSalesAmount: Month;
  currBudgetAmount: Month;
  preSalesAmount: Month;
  growthRate: Month;
  progressRate: Month;
}


/**
 * 月份
 *
 * @export
 * @interface 月份
 */

export interface Month {
  [key: string]: number;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  june: number;
  july: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
  sum: number;
  cur: number;
}

/**
 * 預算版本紀錄
 *
 * @export
 * @class BudgetVersions
 */
export class BudgetVersions {
  placeGropName: string;
  year:string;
  updateDatetime: any;
  updateUser: string;
  version: number;
}


/**
 * 基本業績
 *
 * @export
 * @class BasicPerformance
 */

export class BasicPerformance {
  constructor() {
    this.placeGroupCode = '',
    this.placeGroupName = '',
    this.currBudgetAmount = 0,
    this.currSalesAmount = 0,
    this.currProgressRate = 0,
    this.preSalesAmount = 0,
    this.growthRate = 0
  }
  placeGroupCode?: string; // 客戶代碼
  placeGroupName?: string; // 客戶名稱
  currBudgetAmount: number; // 當月預算
  currSalesAmount: number; // 當月實際業績
  currProgressRate: number | null; // 業績達成率
  preSalesAmount: number;// 去年同期業績
  growthRate: number | null;  //成長率
  productGroupCode?: string; //產品代碼
  productGroupName?: string; //產品名稱
  rangePreSalesAmount?: number; //去年同期業績範圍
}

/**
 * 總銷售業績
 *
 * @export
 * @class TotalSalesPerformance
 */

export class TotalSalesPerformance  extends BasicPerformance {
  [key: string]: any;
  constructor() {
    super();
    this.currDifference = 0,
    this.preDifference = 0,
    this.salesProportion = 0,
    this.isToggle = false
  }
  currDifference: number; // 預算差異
  preDifference: number; //去年同期差異
  salesProportion?:number | null; //業績占比
  isToggle?: boolean; // 是否展開
  isSelected?: boolean; // 是否選取
  isDisplaySelected?: boolean; // 是否顯示選取
}

/**
 * 成長率銷售業績
 *
 * @export
 * @class RevenueGrowthPerformance
 */

export class RevenueGrowthPerformance extends BasicPerformance {
  constructor() {
    super();
    this.year = '',
    this.month = ''
  }
  year: string; // 年度
  month: string; // 月份
  progressRate: number | null; // 銷售成長率
}


/**
 * 報表API篩選條件
 *
 * @export
 * @class ReportFilter
 */
export class ReportFilter{
  fromYear: string;
  fromMonth?: string;
  fromDate?: string;
  toYear?: string;
  toMonth?: string;
  toDate?: string;
  type?: 'Sap' | 'Sale';
  placeGroupCodes?: string[];
  productGroupCodes?: string[];
}


/**
 * 財年日期計算
 *
 * @export
 * @class DateRangeCalculator
 */

export class DateRangeCalculator {
  selectedYear: number;
  selectedMonth: number;

  constructor(year: number, month: number) {
    this.selectedYear = year;
    this.selectedMonth = month;
  }

  // 取得操作的財年日期
  getCurrentMonthRange() {
    return {
      fromYear: this.selectedYear.toString(),
      fromMonth: this.selectedMonth.toString().padStart(2, '0'),
      toYear: this.selectedYear.toString(),
      toMonth: this.selectedMonth.toString().padStart(2, '0')
    };
  }

  // 取得財報年度日期範圍
  getFinancialYearRange() {
    let startYear = this.selectedMonth >= 3 ? this.selectedYear : this.selectedYear - 1;
    let endYear = startYear + 1;

    let startMonth = 3; //財報年度開始於3月
    let endMonth = 2; // 財報年度結束於次年2月

    if (this.selectedYear === new Date().getFullYear() && this.selectedMonth > 2 && this.selectedMonth >= new Date().getMonth() -1){
      endYear = startYear;
      endMonth = new Date().getMonth();
    }

    return {
      fromYear: startYear.toString(),
      fromMonth: startMonth.toString().padStart(2, '0'),
      toYear: endYear.toString(),
      toMonth: endMonth.toString().padStart(2, '0')
    };
  }

  // 取得前一年財年範圍
  getPreviousFinancialYearRange() {
    const fromYear = this.selectedMonth >= 3 ? this.selectedYear - 1 : this.selectedYear - 2;
    const toYear = fromYear + 1;
    return {
      fromYear: fromYear.toString(),
      fromMonth: '03',
      toYear: toYear.toString(),
      toMonth: '02'
    };
  }
}
