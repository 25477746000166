<div class="mx-3 xl:ml-0">
  <div class="bg-white rounded-lg p-2 lg:p-4 xl:p-4 mb-4">
  <!-- 欄位篩選區 -->
  <div class="box-border w-full py-1">
    <app-input-filters [configs]="inputs" [loading]="loading" (exportFormValues)="updateSelectedDate($event)"
      class="w-full"></app-input-filters>
  </div>
  <div class="box-border w-full">
    <app-multi-select-filters [data]="selectedClients" [loading]="loading" (updateSelected)="updateSelectedClient($event)"
      class="w-full"></app-multi-select-filters>
  </div>
  </div>

  @if (!loading) {
    <!-- 可折疊的table -->
    <div class="mb-8">
      <!-- 當月業績 -->
      <div class="flex justify-between mb-1">
        <h2 class="text-dangerColor text-lg font-bold">{{selectedPerformanceDate.fromYear}}-{{selectedPerformanceDate.fromMonth}}</h2>
        <app-email></app-email>
      </div>
      <app-sales-table [data]="currentPerformance" (toggleStatus)="toogleSubPerformane($event)"></app-sales-table>
    </div>

    <div class="my-8">
      <div class="flex justify-between mb-1">
        <h2 class="text-dangerColor text-lg font-bold">{{ currentYearPermanceDate.fromYear}}-{{ currentYearPermanceDate.fromMonth}} ~ {{ currentYearPermanceDate.toYear}}-{{ currentYearPermanceDate.toMonth}}</h2>
        <app-email></app-email>
      </div>
      <app-sales-table [data]="currentYearPerformance"></app-sales-table>
    </div>


    <div class="my-8">
        <div class="flex justify-between mb-1">
          <h2 class="text-dangerColor text-lg font-bold">{{ preYearPermanceDate.fromYear}}-{{ preYearPermanceDate.fromMonth}} ~ {{ preYearPermanceDate.toYear}}-{{preYearPermanceDate.toMonth}}</h2>

          <app-email></app-email>
        </div>
        <app-sales-table [data]="preYearPerformance"></app-sales-table>
      </div>

  }@else{
    <app-loading-spinner></app-loading-spinner>
  }

</div>
