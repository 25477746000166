<div class="mx-3 xl:ml-0 ">
  <div class="bg-white rounded-lg p-2 lg:p-4 xl:p-4">
    <div class="box-border flex flex-col lg:flex-row w-full py-2">
      <app-input-filters class="grow" [configs]="selectedSingleInputs" (exportFormValues)="updateDateSelected($event)"></app-input-filters>
      <div class="w-full lg:w-1/12 flex lg:flex-row-reverse">
        <button type="submit" [disabled]="!filterForm.valid" (click)="exportReport()"
        class="lg:m-2 mt-2 w-full p-2 disabled:bg-primaryHoverColor bg-primaryColor text-xs hover:bg-primaryHoverColor text-sm font-medium rounded-lg text-white">
        匯出
      </button>
      </div>
    </div>
    <div class="box-border w-full pb-2">
      <app-multi-select-filters [data]="selectedClients" (updateSelected)="updateClientSelected($event)"
        class="w-full"></app-multi-select-filters>
        <!-- <app-multi-select-filters [data]="selectedProducts" (updateSelected)="updateProductsSelected($event)"
      class="w-full"></app-multi-select-filters> -->
    </div>
  </div>
</div>
