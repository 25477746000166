import { environment } from '../../../environments/environments';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Result } from '../../../shared/shared.models';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiURL = environment.ServerApiUrl;
  private token: string;
  httpOptions = {
    headers: new HttpHeaders({
      Accept: '*/*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.oidcSecurityServices.getAccessToken(),
    })
  };

  constructor(
    private http: HttpClient,
    private oidcSecurityServices: OidcSecurityService
  ) {
    this.oidcSecurityServices.getAccessToken()
    .subscribe((token) => {
      this.token = token;
      this.httpOptions = {
        headers: new HttpHeaders({
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        })
      }
    });
  }

  // get(取得資料)
  public get<T>(actionUrl: string): Observable<Result<T>> {
    return this.http.get<Result<T>>(this.apiURL + actionUrl, { headers: this.httpOptions.headers });
  }
  public getByParams<T>(actionUrl: string, paramsObj: any): Observable<Result<T>> {
    return this.http.get<Result<T>>(this.apiURL + actionUrl, { headers: this.httpOptions.headers, params: new HttpParams({ 'fromObject': paramsObj }) });
  }


  // post(新增資料)
  public post(actionUrl: string, body: any): Observable<Result<any>> {
    return this.http.post<Result<any>>(this.apiURL + actionUrl, body, { headers: this.httpOptions.headers });
  }

  // post(上傳檔案)
  public postFile(actionUrl: string, body: any): Observable<Result<any>> {
    const uploadFileHeaders = new HttpHeaders({
      Accept: '*/*',
      Authorization: 'Bearer ' + this.token,
    })
    return this.http.post<Result<any>>(this.apiURL + actionUrl, body, { headers: uploadFileHeaders});
  }

}
