import { Component, Injectable } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from '../shared/components/sidebar/sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthModule, OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from './core/services/auth.service';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [SidebarComponent, RouterOutlet, ToastrModule, AuthModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Lxz-Report';

  constructor(private authService: AuthenticationService ) {

  }
  ngOnInit(): void {
    this.authService.handleCallback();
  }

}
