import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [],
  templateUrl: './modal.component.html'
})
export class ModalComponent {

  @Input() isVisiable: boolean = false;
  @Input() title: string = '';

  constructor() { }

  open() {
    this.isVisiable= true;
  }

  close() {
    this.isVisiable = false;
  }
}
