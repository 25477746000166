import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '../components/sidebar/sidbar.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private selectedMainMenuItem = new BehaviorSubject<MenuItem>(new MenuItem());
  private sidebarVisibleSubject = new BehaviorSubject<boolean>(false);
  private selectedMenuItemName = new BehaviorSubject<string>('');
  currentMainMenuItem = this.selectedMainMenuItem.asObservable();
  currentMenuItemName = this.selectedMenuItemName.asObservable();
  sidebarVisible$ = this.sidebarVisibleSubject.asObservable();



  constructor() { }

  /**
    * 設定當前選中的菜單名稱
    * @param item 主選單
    * @param subitem 子選單(非必填)
    */
  setCurrentRouteItem(item: MenuItem, subitem: string = '') {
    this.selectedMainMenuItem.next(item);
    this.selectedMenuItemName.next(subitem);
  }

  /**
  * 主選單展開/收起與設定
  * @param isVisible 是否展開
  *
  */
  toggleSidebar() {
    this.sidebarVisibleSubject.next(!this.sidebarVisibleSubject.value);
  }




}
