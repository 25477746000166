
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterConfig } from '../../shared.models';
import { Places } from '../../../app/feature/reports/report.models';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-multi-select-filters',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './multi-select-filters.component.html',
  styleUrl: './multi-select-filters.component.scss'
})
export class MultiSelectFiltersComponent {
  @Input() data: FilterConfig;
  @Output() updateSelected = new EventEmitter();
  @Input() loading: boolean;

  showDropDown: boolean = true;
  selectedItems: string[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange) {
      this.updateSelected.emit(this.data.options);
    }
  }

  togglePlaceSelection(place: Places) {
    place.isSelected = !place.isSelected;
    place.active = true;

    this.data.options.forEach((data: any) => {
      data.placeVMs.forEach((item: any) => {
        if (item.placeCode !== place.placeCode) {
          item.active = false;
        }

        if (data.placeGroupName === place.placeGroupName && place.placeCode === 'ALL') {
          item.isSelected = place.isSelected;
        }
        if (place.placeCode !== 'ALL' && item.placeCode === 'ALL') {

          item.isSelected = data.placeVMs.filter((place: any)=> place.placeCode !== 'ALL').every((place: any) => place.isSelected);
        }
      });

    });
    this.updateSelected.emit(this.data.options);
  }


  toggleProductSelection(item: { code: string, name: string, isSelected: boolean, active: boolean }) {
    item.isSelected = !item.isSelected;
    item.active = true;
    this.data.options.forEach((pdts: any) => {
      if (pdts.code !== item.code) {
        pdts.active = false;
      }
      if (item.code === 'ALL') {
        pdts.isSelected = item.isSelected;
      }
      if (pdts.code === 'ALL' && item.code !== 'ALL') {
        pdts.isSelected = this.data.options.filter((pdt: any) => pdt.code !== 'ALL').every((pdt: any) => pdt.isSelected);
      }
    });
    this.updateSelected.emit(this.data.options);
  }
}
