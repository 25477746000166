import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { PerformanceGroup, ReportFilter, PlaceGroupsWithPlaces, TotalSalesPerformance, PlaceGroupsWithRevenueGrowth, ProductGroupsWithRevenueGrowth, Products} from '../report.models';
import { BehaviorSubject, Observable, catchError, firstValueFrom, map, mergeMap, of, reduce } from 'rxjs';
import { FilterConfigOptions } from '../../../../shared/shared.models';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private _currentPerformance$ = new BehaviorSubject<PerformanceGroup[]>([]);
  constructor(
    private apiService: ApiService,
    private noticePop: ToastrService
  ) { }

  get currentPerformance$() {
    return this._currentPerformance$.asObservable();
  }

  updatePerformance(data: PerformanceGroup[]) {
    this._currentPerformance$.next(data);
  }

  //取得客戶(類別)列表
  getCustomersGroupDetails(byRole: boolean = false): Observable<PlaceGroupsWithPlaces[]>{
    return this.apiService.get<PlaceGroupsWithPlaces[]>(`System/Places?byRole=${byRole}`)
    .pipe(
      map(res => res.Data),
      catchError(err => {
        return of([]);
      })
    );
  }


  //取得客戶列表
  getCustomers() {
    return this.getCustomersGroupDetails().pipe(
      mergeMap(res => res.flatMap(group => group.placeVMs)),
      map(place => ({
        name: place.placeName,
        value: place.placeCode
      })),
      reduce((acc: FilterConfigOptions[], placeConfig: FilterConfigOptions) => [...acc, placeConfig], [])
    );
  }

  //取得產品類別列表
  getProductCategories(byRole: boolean = false): Observable<Products[]>{
    return this.apiService.get<Products[]>(`System/ProductGroups?byRole=${byRole}`)
    .pipe(
      map(res => res.Data),
      catchError(err => {
        return of([]);
      })
    );
  }

  //取得2022到今年
  getYears(): Observable<string[]> {
    const startedYear = 2022;
    const endedYear = new Date().getFullYear();
    const years = Array.from({ length: endedYear - startedYear + 1 }, (v, k) => (k + startedYear).toString());
    return of(years);
  }

  //取得月份
   getMonths(): Observable<string[]> {
    const months = Array.from({ length: 12}, (v, k) => k + 1);
    const monthToString = months.map(m => m.toString().padStart(2, '0'));
    return of(monthToString);
  }

  //取得客戶的年預算版本紀錄
  async getCustomerBudgetVersion(params: { place: string, year: string }): Promise<any> {
    return ((await firstValueFrom(this.apiService.getByParams('System/BudgetVersions', params))).Data ?? []);
  }

  //匯入預算
  async uploadFile(file: any): Promise<boolean>{
     const result = (await firstValueFrom (this.apiService.postFile('Data/upload', file)));
     if (result.Success) [
      this.noticePop.success('匯入成功')
     ]
     return result.Success
  }

  //取得業績資料
  async takeSalePerformanceData(params: ReportFilter): Promise<PerformanceGroup[]>{
    try {
      const mapData = (await firstValueFrom(this.apiService.post('Report/SaleSummaryReport', params))).Data ?? [];
      mapData.forEach((item: any) => {
        item.performance = new TotalSalesPerformance();
      });
      return mapData;
    } catch (error) {
      this.noticePop.error('取得業績資料失敗');
      throw error;
    }
  }

  //取得客戶YOY報表
  async takePlaceRevenueGrowth(params: ReportFilter): Promise<PlaceGroupsWithRevenueGrowth[]> {
    const mapdata = (await firstValueFrom(this.apiService.post('Report/RevenueGrowthRate/Place', params))).Data ?? [];
    return mapdata;
  }

  //取得產品YOY報表
  async takeProductRevenueGrowth(params: ReportFilter): Promise<ProductGroupsWithRevenueGrowth[]> {
    const mapdata = (await firstValueFrom(this.apiService.post('Report/RevenueGrowthRate/Product', params))).Data ?? [];
    return mapdata;
  }

  //取得產品/客戶YOY報表
  async takeProductAndClientsRevenueGrowth(params: ReportFilter): Promise<any[]> {
    const mapdata = (await firstValueFrom(this.apiService.post('Report/RevenueGrowthRate/PP', params))).Data ?? [];
    return mapdata;
  }

  // 匯出報表
  async exportReport(params: ReportFilter) {
    try {
      (await firstValueFrom(this.apiService.post('Data/download', params)));
      this.noticePop.success('匯出資料成功，請至信箱查收');
    } catch (error) {
      this.noticePop.error('匯出資料失敗');
      throw error;
    }
  }
}
