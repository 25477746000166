import { Component, EventEmitter, Input, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { PerformanceGroup, TotalSalesPerformance, Places } from '../../report.models';
import { CommonModule } from '@angular/common';
import { CustomPercentPipe } from '../../../../../shared/pipe';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-sales-table',
  standalone: true,
  imports: [CommonModule, CustomPercentPipe],
  templateUrl: './sales-table.component.html'
})
export class SalesTableComponent {
  @Input() data: PerformanceGroup[] = new Array<PerformanceGroup>();
  @Output() toggleStatus: EventEmitter<PerformanceGroup> = new EventEmitter<PerformanceGroup>();
  loadingData: boolean = true;
  sumPerformance: TotalSalesPerformance = new TotalSalesPerformance();
  sortColumn: string;
  sortOrder: 'asc' | 'desc';
  dynamicColHalfWidth: string;
  dynamicColWidth: string;
  dynamicColDoubleWidth: string;


  constructor(
    private reportService: ReportService
  ) {

    this.reportService.currentPerformance$.subscribe((data: any) => {
      this.data = data;
      if(this.data) {
        this.updatePerformanceData();
        const numberOfColumns = 10;
        const columnWidth = 100 / numberOfColumns;
        this.dynamicColWidth = `${columnWidth}%`;
        this.dynamicColHalfWidth = `${columnWidth / 2}%`;
        this.dynamicColDoubleWidth = `${columnWidth * 2}%`;
      }
      this.sortOrder = 'asc';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.updatePerformanceData();
    }
  }


  setSort(column: string): void {
    this.sortColumn = column;
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.sortData(column);
  }
  shouldDisplayDetails(item: PerformanceGroup): boolean {
    return item.details.length > 1 && item.details[0].placeGroupName !== '其他';
  }

    sortData(column: keyof PerformanceGroup | keyof TotalSalesPerformance): void {
      this.data.sort((a: PerformanceGroup, b: PerformanceGroup) => {
        const valueA = a.performance[column];
        const valueB = b.performance[column];
        return (valueA < valueB ? -1 : 1) * (this.sortOrder === 'asc' ? 1 : -1);
      });

        this.data.forEach(item => {
          if (this.shouldDisplayDetails(item)) {
            item.details.sort((a:TotalSalesPerformance, b: TotalSalesPerformance) => {
              const valueA = a[column];
              const valueB = b[column];
              return (valueA < valueB ? -1 : 1) * (this.sortOrder === 'asc' ? 1 : -1);
            });
          }

        });
    }

  updatePerformanceData() {
    const updatedData = this.data.map(group => {
      const visibleDetails = group.details.filter(detail => detail.isSelected);
      const hiddenDetails = group.details.filter(detail => !detail.isSelected);

      const other = this.aggregateOtherData(hiddenDetails);
      if (other) {
        visibleDetails.push(other);
      }

      return {
        ...group,
        details: visibleDetails
      };
    });
    this.data = updatedData;
    this.calClientGroupPermance();
  }

  toggleSubDetails(item: PerformanceGroup) {
    item.performance.isToggle = !item.performance.isToggle;
    this.toggleStatus.emit(item);
  }

  private aggregateOtherData(details: TotalSalesPerformance[]): TotalSalesPerformance | null {
    if (details.length === 0) {
      return null;
    }

    const totalCurrBudgetAmount = details.reduce((sum, detail) => sum + detail.currBudgetAmount, 0);
    const totalCurrSalesAmount = details.reduce((sum, detail) => sum + detail.currSalesAmount, 0);
    const totalPreSalesAmount = details.reduce((sum, detail) => sum + detail.preSalesAmount, 0);

    return {
      placeGroupCode: '',
      placeGroupName: '其他',
      currBudgetAmount: totalCurrBudgetAmount,
      currSalesAmount: totalCurrSalesAmount,
      currDifference: totalCurrSalesAmount - totalCurrBudgetAmount,
      currProgressRate: totalCurrBudgetAmount > 0 ? (totalCurrSalesAmount / totalCurrBudgetAmount) : null,
      preSalesAmount: totalPreSalesAmount,
      preDifference: totalCurrSalesAmount - totalPreSalesAmount,
      growthRate: totalPreSalesAmount > 0 ? ((totalCurrSalesAmount - totalPreSalesAmount) / totalPreSalesAmount) : null,
      salesProportion: details.reduce((sum, detail) => sum + (detail.salesProportion ?? 0), 0),
      isSelected: true
    };
  }

  // 計算業績總銷售
  calTotalSales(): void {
    this.calSumPerformance();
    this.data.forEach(item => {
      item.detailsTotalSalesAmount = item.details.reduce((sum, detail) => sum + detail.currSalesAmount, 0);
    });

    this.calProportions();
  }

  //計算總計
  calSumPerformance(): void {
    this.sumPerformance.currBudgetAmount = this.data.reduce((sum, item) => sum + item.performance.currBudgetAmount, 0);
    this.sumPerformance.currSalesAmount = this.data.reduce((sum, item) => sum + item.performance.currSalesAmount, 0);
    this.sumPerformance.currDifference = this.sumPerformance.currSalesAmount - this.sumPerformance.currBudgetAmount;

    this.sumPerformance.currProgressRate = this.sumPerformance.currBudgetAmount > 0 ?
      this.sumPerformance.currSalesAmount / this.sumPerformance.currBudgetAmount : null;

    this.sumPerformance.preSalesAmount = this.data.reduce((sum, item) => sum + item.performance.preSalesAmount, 0);
    this.sumPerformance.preDifference = this.sumPerformance.currSalesAmount - this.sumPerformance.preSalesAmount;

    this.sumPerformance.growthRate = this.sumPerformance.preSalesAmount > 0 ?
      (this.sumPerformance.currSalesAmount - this.sumPerformance.preSalesAmount) / this.sumPerformance.preSalesAmount : null;

    this.sumPerformance.salesProportion = 1;
  }

  //計算各客戶類別/客戶占比
  calProportions(): void {

    this.data = this.data.map(item => {
      const itemProportion = (item.performance.currSalesAmount / this.sumPerformance.currSalesAmount);
      const details = item.details.map(detail => {
        const detailProportion = (detail.currSalesAmount / (item.detailsTotalSalesAmount ?? 0));
        return {
          ...detail,
          salesProportion: detailProportion
        };
      });

      return {
        ...item,
        performance: {
          ...item.performance,
          salesProportion: itemProportion
        },
        details: details
      };
    });
  }

  updateReport(selectedPlaces: Places[]): void {
    this.data.forEach((item) => {
      item.details = item.details.filter(detail => selectedPlaces.some(place => place.placeCode === detail.placeGroupCode));
    });
  }

  // 計算客戶類別業績
  calClientGroupPermance(): void {
    this.data.forEach(item => {
      item.performance.preSalesAmount = item.details.filter(i => i.isSelected).reduce((sum, detail) => sum + detail.preSalesAmount, 0);
      item.performance.currBudgetAmount = item.details.filter(i => i.isSelected).reduce((sum, detail) => sum + detail.currBudgetAmount, 0);
      item.performance.currSalesAmount = item.details.filter(i => i.isSelected).reduce((sum, detail) => sum + detail.currSalesAmount, 0);
      item.performance.currDifference = item.details.filter(i => i.isSelected).reduce((sum, detail) => sum + detail.currDifference, 0);
      item.performance.preDifference = item.details.filter(i => i.isSelected).reduce((sum, detail) => sum + detail.preDifference, 0);
      item.performance.salesProportion = item.details.filter(i => i.isSelected).reduce((sum, detail) => sum + (detail.salesProportion ?? 0), 0);
      item.performance.currProgressRate = item.performance.currBudgetAmount > 0 ?
        item.performance.currSalesAmount / item.performance.currBudgetAmount : null;
      item.performance.growthRate = item.performance.preSalesAmount > 0 ?
        (item.performance.currSalesAmount - item.performance.preSalesAmount) / item.performance.preSalesAmount : null;
    });

    this.calTotalSales();
    this.loadingData = false;
  }




}
