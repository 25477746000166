import { Component } from '@angular/core';
import { SinglerevenuegrowthrateTableComponent} from '../single-revenuegrowthrate-table/single-revenuegrowthrate-table.component';
import { ActivatedRoute} from '@angular/router';
import { MultiSelectFiltersComponent } from '../../../../../shared/components/multi-select-filters/multi-select-filters.component';
import { InputFiltersComponent } from '../../../../../shared/components/input-filters/input-filters.component';
import { debounceTime, map, Subject } from 'rxjs';
import { ReportService } from '../../services/report.service';
import { FilterConfig, FilterConfigOptions } from '../../../../../shared/shared.models';
import { Month, PlaceGroupsWithPlaces, Products, ReportFilter, RevenueGrowthList } from '../../report.models';
import { LoadingSpinnerComponent } from '../../../../../shared/components/loading-spinner/loading-spinner.component';
@Component({
  selector: 'app-revenuegrowthrate-display',
  standalone: true,
  imports: [SinglerevenuegrowthrateTableComponent, MultiSelectFiltersComponent, InputFiltersComponent, LoadingSpinnerComponent],
  templateUrl: './revenuegrowthrate-display.component.html',
  styleUrl: './revenuegrowthrate-display.component.scss'
})
export class RevenuegrowthrateDisplayComponent {

  inputs: FilterConfig[];
  years: FilterConfigOptions[];
  reportType: 'clients' | 'products-clients' | 'products' | string = '';
  loading: boolean = true;

  filter: ReportFilter = { fromYear: new Date().getFullYear().toString()};

  clients: PlaceGroupsWithPlaces[];
  products: Products[];

  singleDropdownSelectedValue: FilterConfig;
  multiDropdownSelectedValue: FilterConfig;

  selectedProducts: FilterConfig;

  report: {year: number, data: RevenueGrowthList[]};

  constructor(
    private activeRoute: ActivatedRoute,
    private reportService: ReportService
  ) {
  }

  async ngOnInit() {

    this.activeRoute.params.subscribe(params => {
      this.reportType = params['type'];

      //取得YOY報表類型
      switch (this.reportType) {
        case 'clients': {
          //取得客戶(類別)資料 傳遞給multi-select-filters
          this.reportService.getCustomersGroupDetails(false)
            .subscribe(async (data) => {
              this.clients = data;
              this.clients.forEach((item) => {
                item.placeVMs.forEach((place) => {
                  place.isSelected = true;
                 });
                item.placeVMs.unshift({ placeCode: 'ALL', placeName: '全部', placeGroupName: item.placeGroupName, isSelected: true })
              });
              this.singleDropdownSelectedValue = { key: 'clients', type: 'multi-select', label: '客戶', options: this.clients }
              this.filter.placeGroupCodes = [];
            });
          break;
        }
        case 'products': {
          //取得產品(類別)資料 傳遞給multi-select-filters
          this.reportService.getProductCategories(false)
            .subscribe((data) => {
              data.forEach((product) => {
                product.isSelected = true;
              });
              this.products = data;
              this.products.unshift({ code: 'ALL', name: '全部', isSelected: true });
              this.singleDropdownSelectedValue = { key: 'products', type: 'multi-select', label: '產品', options: this.products }
            });
          break;
        }
      }

      this.setOptions();
    });

  }

  //初始化YOY資料
  createInitialData(groupName: string): RevenueGrowthList {
    return {
      rowName: groupName,
      currSalesAmount: this.initializeMonthlyData(),
      preSalesAmount: this.initializeMonthlyData(),
      growthRate: this.initializeMonthlyData(),
      currBudgetAmount: this.initializeMonthlyData(),
      progressRate: this.initializeMonthlyData(),
    };
  }

  // 初始化月份
  initializeMonthlyData(): Month {
    return {
      jan: 0, feb: 0, mar: 0, apr: 0, may: 0, june: 0,
      july: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0, sum: 0, cur: 0
    };
  }

  //轉換YOY資料
  transformData(data: any[]) {
    const monthMapping: { [key: string]: string } = {
      "1": 'jan', "2": 'feb', "3": 'mar', "4": 'apr', "5": 'may', "6": 'june',
      "7": 'july', "8": 'aug', "9": 'sep', "10": 'oct', "11": 'nov', "12": 'dec', "SUM": 'sum', "CUR": 'cur'
    };

    return data.map(group => {

      let typeName = '';
      if (this.reportType === 'products') {
        typeName = group.productGroupName;
      } else if (this.reportType === 'clients') {
        typeName = group.placeGroupName;
      }
      let initialData: RevenueGrowthList = this.createInitialData(typeName);

      group.datas.forEach((item: any) => {
        const month = monthMapping[item.month];
        if (month) {
          initialData.currSalesAmount[month] = item.currSalesAmount || 0;
          initialData.preSalesAmount[month] = item.preSalesAmount || 0;
          initialData.growthRate[month] = item.growthRate || null;
          initialData.currBudgetAmount[month] = item.currBudgetAmount || 0;
          initialData.progressRate[month] = item.progressRate || null;
        }
      });

      return initialData;
    });
  }

  //設定表單欄位
  setOptions() {
    this.reportService.getYears()
      .pipe(
        map(y => y.map(y => ({ value: y })))
      )
      .subscribe(years => {
        this.years = years;
        this.inputs = [
          { key: 'year', type: 'select-single', label: '年度', options: this.years, defualtValue: this.filter.fromYear},
        ];
      });
  }

  //更新日期篩選
  async updateSelectedDate(value: any){
    this.filter.fromYear = value.year;
    switch (this.reportType) {
      case 'products': {
        if (this.filter.productGroupCodes) this.getYOYReport();
        break;
      }
      case 'clients' : {
        if (this.filter.placeGroupCodes) this.getYOYReport();
        break;
      }
    }

  }

  //更新下拉選單篩選
  async updateSelectedType(item: any[]){
    switch (this.reportType) {
      case 'products': {
        this.filter.productGroupCodes = this.filterSelectedProducts(item);
        break;
      }
      case 'clients' : {
        this.filter.placeGroupCodes = this.filterSelectedClients(item);
        break;
      }
    }

    this.getYOYReport();
  }

  //篩選選取客戶
  filterSelectedClients(item: PlaceGroupsWithPlaces[]): string[]{
      const selectedPlaces: string[] = [];
      item.forEach((group) => {
        if (group.placeVMs.some((place) => place.placeCode === 'ALL' && place.isSelected)) {
          selectedPlaces.push(...group.placeVMs.map((place) => place.placeCode));
        } else {
          selectedPlaces.push(...group.placeVMs.filter((place) => place.isSelected).map((place) => place.placeCode));
        }
      });
      return selectedPlaces;
  }

  //篩選選取產品
  filterSelectedProducts(item: {code: string, name: string, isSelected: boolean}[]): string[]{
    const selectedProducts: string[] = [];
    item.forEach((product) => {
      selectedProducts.push(...item.filter(product => product.isSelected).map(product => product.code));
    });
    return selectedProducts;
  }

  // 取得YOY報表API
  async getYOYReport(){
    this.loading = true;
    switch (this.reportType) {
      case 'clients': {
        // this.count += 1;
        // if (this.count >= 1) {
        //   const mapData = this.transformData(this.originalReportData).filter(item =>
        //     this.filter.placeGroupCodes && this.filter.placeGroupCodes.includes(item.rowCode)
        //   );

        //   this.report = { year: Number(this.filter.fromYear), data: mapData };

        // } else {
        //   const apiData = await this.reportService.takePlaceRevenueGrowth(this.filter);
        //   this.report = { year: Number(this.filter.fromYear), data: this.transformData(apiData) };
        //   this.originalReportData = apiData;
        //   this.count += 1;
        // }
        const apiData = await this.reportService.takePlaceRevenueGrowth(this.filter);
        this.report = { year: Number(this.filter.fromYear), data: this.transformData(apiData) };
        this.loading = false;
        break;
      }
      case 'products': {
        const apiData = await this.reportService.takeProductRevenueGrowth(this.filter);
        this.report = { year: Number(this.filter.fromYear), data: this.transformData(apiData) };
        this.loading = false;
        break;
      }
    }
  }

}
