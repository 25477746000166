import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  standalone: true,
  imports: [],
  template: `  <div class="border flex justify-center flex-col items-center w-100 h-[calc(100vh-5rem)]">
  <h1 class="text-2xl text-gray-500 loading-container">
    <img class="inline-block w-14 mr-2 loading-img" src="../../../../../assets/img/loadingImg.png" alt="">
    <img class="inline-block w-14 mr-2 loading-img" src="../../../../../assets/img/loadingImg.png" alt="">
    <img class="inline-block w-14 mr-2 loading-img" src="../../../../../assets/img/loadingImg.png" alt="">

  </h1>
  <h2 class="mt-3 text-gray-500">資料正在加載中，操作請稍後</h2>


</div>`,
})
export class LoadingSpinnerComponent {

}
