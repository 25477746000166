<aside class="fixed top-0 left-0">
  <div>
    <a class="flex items-center gap-1 py-5 px-7 xl:px-[.9rem] lg:px-[.9rem]" href="/">
      <svg version="1.1" id="圖層_1" class="h-[1.55rem] w-[1.55rem]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 283.46 283.46" style="enable-background:new 0 0 283.46 283.46;" xml:space="preserve">
        <style type="text/css">
          .st0 {
            fill: #C10230;
          }
        </style>
        <g>
          <path class="st0" d="M141.39,8.38C67.7,8.38,7.95,68.13,7.95,141.83c0,73.7,59.75,133.44,133.44,133.44
       c73.71,0,133.44-59.75,133.44-133.44C274.83,68.13,215.09,8.38,141.39,8.38z M141.39,248.58c-58.95,0-106.74-47.8-106.74-106.74
       c0-58.97,47.8-106.78,106.74-106.78c58.96,0,106.75,47.81,106.75,106.78C248.14,200.78,200.35,248.58,141.39,248.58z" />
          <path class="st0" d="M194.91,67.69L194.91,67.69l-0.06,0c-0.03,0-0.05,0-0.07,0c-0.02,0-0.04,0-0.06,0h-42.65V56.1h-21.35v11.59
       h-42.7H68.44h-1.77v148.54h21.35V89.05h37.37c2.95,0,5.33,2.39,5.33,5.34c0,2.95-2.38,5.34-5.33,5.34h-5.53l-0.01,0.01
       c-11.61,0.09-20.99,9.45-21.14,21.06l-0.01,0v10.95v21.34v63.13h21.35v-57.79c0-2.95,2.39-5.34,5.35-5.34
       c2.95,0,5.33,2.4,5.33,5.34v57.79h21.35v-63.38l-0.01-0.01c-0.13-11.53-9.42-20.85-20.94-21.07l-0.01-0.02h-5.72
       c-2.95,0-5.35-2.39-5.35-5.34c0-2.94,2.39-5.33,5.35-5.33h5.72l0.02-0.03c11.54-0.21,20.83-9.56,20.95-21.12h0v-0.06
       c0-0.05,0.01-0.11,0.01-0.14c0-0.04-0.01-0.08-0.01-0.11V89.05h37.36c2.95,0,5.34,2.39,5.34,5.34c0,2.95-2.39,5.34-5.34,5.34h-5.88
       l-0.01,0.02c-11.53,0.3-20.79,9.71-20.79,21.32c0,0.21,0.03,0.42,0.04,0.63l-0.04,0.02v10.03v21.34v63.13h21.35v-57.79
       c0-2.95,2.39-5.34,5.34-5.34c2.95,0,5.34,2.4,5.34,5.34v57.79h21.34v-63.79l-0.03-0.02c-0.36-11.44-9.69-20.59-21.2-20.66l0,0
       h-5.45c-2.95,0-5.34-2.39-5.34-5.34c0-2.94,2.38-5.33,5.34-5.33h6l0.01-0.05c11.13-0.34,20.1-9.2,20.62-20.3h0.05V88.59h-0.02
       C215.86,77.05,206.48,67.77,194.91,67.69z" />
        </g>
      </svg>
      <h6 class="block antialiased tracking-normal text-xl lg:text-base xl:text-[1.2em] leading-relaxed text-gray">報表管理系統</h6>
    </a>
  </div>
  <ul class="mb-4 flex flex-col gap-1">
    @for(item of menu; track $index) {
      <li  style="min-width: 230px;">
        <a [routerLink]="item.link" (click)="updateActiveItem(item)" class="block text-[#a6b0cf] w-full flex items-center hover:text-white"  [class.text-white]="item.active">
          <button type="button" class="middle none font-bold center transition-all text-xs py-2.5 rounded-lg w-full flex items-center gap-3 px-[.9rem] capitalize">
            <span [innerHTML]="item.icon" [class.text-white]="item.active"></span>
            <p routerLinkActive="text-white" [class.text-white]="item.active"  #navlink  class="tracking-wide block antialiased text-base lg:text-sm xl:text-base leading-relaxed font-medium capitalize">{{item.name}}</p>
          </button>
          @if (item.children) {
            <span class="inline-block px-[1.2rem]">
              <svg class="w-[.55rem] h-[.55rem]" [class.text-white]="item.active"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"/>
            </svg>
            </span>
          }

        </a>
        @if(item.children) {
          <ul [class.hidden]="!item.active" class="flex flex-col gap-1">
            @for(subitem of item.children; track $index) {
              <li [class.active]="subitem.active" class="hover:text-activeColor rounded-lg" >
                <a [routerLink]="subitem.link" (click)="updateActiveItem(item, subitem)" class="block  w-full text-[#a6b0cf] pl-8 hover:text-white">
                  <button type="button" class="middle none font-bold center transition-all text-xs py-1.5 rounded-lg w-full flex items-center gap-3 px-[.9rem] capitalize">
                    <p routerLinkActive="text-white"  #navlink class="tracking-wide block antialiased text-base lg:text-sm xl:text-base leading-relaxed font-medium capitalize">{{subitem.name}}</p>
                  </button>
                </a>
              </li>
            }
          </ul>
        }
      </li>
    }
  </ul>

</aside>
