 @if(tableData){
  <div class="scroll-container mt-2">
    <div class="table-wrapper border rounded-lg" #scrollContainer>
      <table class="scroll-area text-sm text-left rtl:text-right w-full whitespace-nowrap">
        <thead class="bg-white">
          <tr class="bg-tableSecondary tracking-wider">
            <th  class="tracking-wide lg:py-3  py-1.5 px-2.5 text-center  border-b  border-tableBorderColor td-primary-r-border rounded-tl-lg min-w-32 lg:min-w-24"></th>
            <th  class="border-b  border-tableBorderColor td-primary-r-border min-w-32 lg:min-w-24"></th>
            @for(product of header; track $index) {
              <th  class="tracking-wide lg:py-3  py-1.5 px-2.5 text-right border-b  border-tableBorderColor  td-primary-r-border min-w-32 lg:min-w-24 " [ngClass]="{'bg-tablePrimary text-white': $index === (header.length -1 ) }">{{ product.productGroupName }}</th>
            }
          </tr>
        </thead>
        <tbody>
          @for (place of tableData; track $index; let i = $index){
            @if(place.rowspan > 1){
              <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white' : ((i+1) % 2) !== 0}">
                <td  class="lg:py-3  py-1.5 px-2.5 td-primary-r-border min-w-32 lg:min-w-24" [ngClass]="{'bg-tablePrimary text-white': tableData.length  === (i+1)}"></td>
                <td  class="lg:py-3  py-1.5 px-2.5 td-primary-b-border td-primary-r-border min-w-32 lg:min-w-24" [ngClass]="{'bg-tablePrimary text-white': tableData.length  === (i+1)}">{{place.year}}</td>
                @for(data of place.productsData; track $index; let j = $index){
                    <td class="lg:py-3  py-1.5 px-2.5  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': j === (place.productsData.length -1 ) || tableData.length  === (i+1)}">{{ data.currSalesAmount | number:'1.0-0' }}</td>
                }
              </tr>
              <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white' : ((i+1) % 2) !== 0}" >
                <td  class="lg:py-3  py-1.5 px-2.5 td-primary-r-border min-w-32 lg:min-w-24 font-bold text-base tracking-wider" [ngClass]="{'bg-tablePrimary text-white': tableData.length  === (i+1)}"></td>
                <td  class="lg:py-3  py-1.5 px-2.5 td-primary-b-border td-primary-r-border min-w-32 lg:min-w-24" [ngClass]="{'bg-tablePrimary text-white': tableData.length  === (i+1)}">{{place.year-1}}累計</td>
                @for(data of place.productsData; track $index; let j = $index){
                    <td class="lg:py-3  py-1.5 px-2.5  td-primary-b-border td-primary-r-border text-right min-w-32 lg:min-w-24" [ngClass]="{'bg-tablePrimary text-white': j === (place.productsData.length -1 ) || tableData.length  === (i+1)}">{{ data.rangePreSalesAmount | number:'1.0-0'  }}</td>
                }
              </tr>
              <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white' : ((i+1) % 2) !== 0}" >
                <td  class="lg:py-3  py-1.5 px-2.5 td-primary-r-border min-w-32 lg:min-w-24 font-bold text-base tracking-wider" [ngClass]="{'bg-tablePrimary text-white': tableData.length  === (i+1)}">{{ place.placeName }}</td>
                <td  class="lg:py-3  py-1.5 px-2.5 td-primary-b-border td-primary-r-border min-w-32 lg:min-w-24" [ngClass]="{'bg-tablePrimary text-white': tableData.length  === (i+1)}">成長率</td>
                @for(data of place.productsData; track $index; let j = $index){
                    <td class="lg:py-3  py-1.5 px-2.5  td-primary-b-border td-primary-r-border text-right min-w-32 lg:min-w-24" [ngClass]="{'bg-tablePrimary text-white': j === (place.productsData.length -1 ) || tableData.length  === (i+1)}">{{ data.growthRate | customPercent }}</td>
                }
              </tr>
              <tr class="border-b-2  border-tableBorderColor" [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white' : ((i+1) % 2) !== 0}" >
                <td  class="lg:py-3  py-1.5 px-2.5 td-primary-r-border min-w-32 lg:min-w-24" [ngClass]="{'bg-tablePrimary text-white': tableData.length  === (i+1)}"></td>
                <td  class="lg:py-3  py-1.5 px-2.5 td-primary-b-border td-primary-r-border min-w-32 lg:min-w-24" [ngClass]="{'bg-tablePrimary text-white': tableData.length  === (i+1)}">{{place.year-1}}合計</td>
                @for(data of place.productsData; track $index; let j = $index){
                    <td class="lg:py-3  py-1.5 px-2.5 td-primary-b-border td-primary-r-border text-right min-w-32 lg:min-w-24"  [ngClass]="{'bg-tablePrimary text-white': j === (place.productsData.length -1 ) || tableData.length  === (i+1)}">{{ data.preSalesAmount | number:'1.0-0' }}</td>
                }
              </tr>
            }
          }
        </tbody>
      </table>
    </div>
  </div>

}
