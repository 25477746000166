import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { RevenueGrowthList } from '../../report.models';
import { CommonModule } from '@angular/common';
import { CustomPercentPipe } from '../../../../../shared/pipe';

@Component({
  selector: 'app-single-revenuegrowthrate-table',
  standalone: true,
  imports: [CommonModule, CustomPercentPipe],
  templateUrl: './single-revenuegrowthrate-table.component.html',
  styleUrl: './single-revenuegrowthrate-table.component.scss'
})
export class SinglerevenuegrowthrateTableComponent {
  @Input() report: { year: number, data: RevenueGrowthList[] };
  // items: any;

  ngOnChanges(changes: SimpleChanges): void {

  }


}
