import { Component } from '@angular/core';
import { InputFiltersComponent } from '../../../../../shared/components/input-filters/input-filters.component';
import { ReportService } from '../../services/report.service';
import { FilterConfig, FilterConfigOptions } from '../../../../../shared/shared.models';
import { forkJoin, map } from 'rxjs';
import { BudgetVersions } from '../../report.models';
import { ModalComponent } from '../../../../../shared/components/modal/modal.component';
import dayjs  from 'dayjs/esm';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-import',
  standalone: true,
  imports: [InputFiltersComponent, ModalComponent, FormsModule ],
  templateUrl: './import.component.html'
})
export class ImportComponent {

  inputs: FilterConfig[];
  clients: FilterConfigOptions[];
  years: FilterConfigOptions[];
  fileData: FormData;
  formData: {place: string, year: string, placeName: string, type: string};
  versions: BudgetVersions[];
  showModal: boolean = false;
  modalTitle: string;
  file: File | null;

  constructor(
    private reportService: ReportService
  ) {

  }

  ngOnInit(): void {
     this.setOptions();
  }

  // 設定表單欄位
  setOptions() {
    forkJoin({
      clients: this.reportService.getCustomers(),
      years: this.reportService.getYears().pipe(
        map(years => years.map(year => ({ value: year }))))
    })
    .subscribe(({ clients, years }) => {
      this.clients = clients || [];
      this.years = years || [];
      this.inputs = [
        { key: 'type', type: 'select-single', label: '上傳類型', options: [{ name: '預算', value: 'BudgetData' }, { name: '銷售', value: 'SalesData' }] },
        { key: 'place', type: 'select-single', label: '客戶', options: this.clients },
        { key: 'year', type: 'select-single', label: '年份(預算必填)', options: this.years }
      ];
    });
  }

  // 取得表單資訊
  getForm(item: any) {

    if (Object.values(item).every(val => val !== null && val !== '')) {
      this.formData = item;
      this.formData.placeName = (this.clients.find(i => i.value === item.place) ?? {}).name || '';
    }

  }

  // 取得客戶的年預算版本紀錄
  async getCustomerBudgetVersion() {
    this.versions = await this.reportService.getCustomerBudgetVersion(this.formData);
    if (this.versions?.length > 0) {
      this.versions.forEach(version => {
        version.updateDatetime = dayjs(version.updateDatetime).format('YYYY-MM-DD HH:mm:ss');
      });
    }
  }

  // 上傳檔案
  uploadFile(event: any) {
    this.fileData = new FormData();
    const file = event.target.files[0];
    if (file) {
        const formData = new FormData();
        formData.append('file', file);
        this.fileData = formData;
        this.fileData.append('type', this.formData.type);
        this.fileData.append('place', this.formData.place);
        this.fileData.append('year', this.formData.year);
    }
  }

  // 匯入年預算
  async importFile() {
    if (this.fileData) {
      const isSuccess = await this.reportService.uploadFile(this.fileData);
      if (isSuccess) {
        //  this.file = null;
         if(this.formData.type === 'BudgetData') {
          this.showModal = !this.showModal;
         }
      }
    }
  }

  refreshInputs() {
    this.inputs = [...this.inputs];
  }

  // 顯示/隱藏預算紀錄modal
  toggleModal(): void {
    switch (this.formData.type) {
      case 'BudgetData': {
        this.showModal = !this.showModal;
        if (this.showModal) {
          this.modalTitle = `${this.formData.placeName+this.formData.year}預算匯入版本紀錄`
          this.getCustomerBudgetVersion();
        }
        break;
      }
      case 'SalesData': {
        this.importFile();
        break;
      }
    }

  }
}
