<form [formGroup]="filterForm">
  <div>
    @for (config of configs; track $index) {
    <div class="relative w-full lg:w-1/6 mr-2 inline-block align-top"
    [ngClass]="{'lg:w-1/12': config.type === 'select-double'}">
      <label [attr.for]="config.key" class="block mb-1 text-primaryHoverColor text-sm font-bold">{{ config.label }}</label>
      @switch (config.type) {
      <!-- 日期選擇 -->
      @case ('date') {
      <input type="date" [id]="config.label" [formControlName]="config.key"
        class="w-full text-sm border rounded-lg p-2 appearance-none cursor-pointer borer-gray-400">
      }
      <!-- 單選下拉選單(雙欄) -->
      @case ('select-double') {
      <div class="relative">
        <select id="dropdownSelect" [formControlName]="config.key"
          class="w-full text-sm border rounded-lg p-2 appearance-none cursor-pointer borer-gray-400">
          <option value="" disabled>請選擇</option>
          @for (item of config.options; track $index) {
            <option [value]="item.value">{{ item.name ? item.name : item.value }}</option>
            }
        </select>
        <svg class="w-2.5 h-2.5 absolute top-1/2 right-3 transform -translate-y-1/2 pointer-events-none"
          aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 4 4 4-4" />
        </svg>
      </div>
      @if (filterForm.get(config.key)?.dirty && filterForm.get(config.key)?.errors?.['required']) {
        <span class="text-xs text-dangerColor" >請選擇{{config.label}}</span>
        }
      }
      <!-- 單選下拉選單(單欄) -->
      @case ('select-single') {
      <div class="relative">
        <select id="dropdownSelect" [formControlName]="config.key"
          class="w-full text-sm border rounded-lg p-2 appearance-none cursor-pointer borer-gray-400">
          <option value=""  disabled>請選擇</option>
          @for (item of config.options; track $index) {
          <option [value]="item.value">{{ item.name ? item.name : item.value }}</option>
          }
        </select>
        <svg class="w-2.5 h-2.5 absolute top-1/2 right-3 transform -translate-y-1/2 pointer-events-none"
          aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 4 4 4-4" />
        </svg>
      </div>
        @if (filterForm.get(config.key)?.dirty && filterForm.get(config.key)?.errors?.['required']) {
        <span class="text-xs text-dangerColor">請選擇{{config.label}}</span>
        }
      }
      <!-- 多選checkbox下拉選單 -->
      <!-- @case ('multiselect') {
      <app-multi-select-filters [data]="config"></app-multi-select-filters>
      } -->
      }
    </div>
    }
  </div>
</form>
