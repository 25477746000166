import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent {

  user: string;

  constructor(
    public oidcSecurityService: OidcSecurityService
  ) {
    this.oidcSecurityService.userData$.subscribe((val) => {
      this.user = val.userData?.nickname ? val.userData?.nickname : val.userData?.email;
    });

  }
  logout() {
    this.oidcSecurityService
      .logoff()
      .subscribe((result) => {
      });
    }
}
