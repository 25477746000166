@if(report) {
  <div class="scroll-container mt-2">
    <div class="table-wrapper border rounded-lg" #scrollContainer>
      <table class="table-dynamic scroll-area text-sm text-left rtl:text-right w-full whitespace-nowrap ">
        <thead class="bg-white">
          <tr class="text-sm bg-tableSecondary">
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor  rounded-tl-lg"></th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black border-b border-tableBorderColor td-primary-r-border text-right"></th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right ">3月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">4月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">5月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">6月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">7月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">8月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">9月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">10月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">11月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">12月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">次年1月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right">次年2月</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor td-primary-r-border text-right bg-tableSecondary text-white" >同期合計</th>
            <th scope="col" class="min-w-32 lg:min-w-24 font-black px-5 py-2.5 lg:py-3  border-b border-tableBorderColor text-right rounded-tr-lg bg-tablePrimary text-white" >合計</th>
          </tr>
        </thead>
        <tbody>
          @for(item of report.data; track $index; let i = $index) {
            <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white' : ((i+1) % 2) !== 0}">
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  border-b-2 border-tableBorderColor td-primary-r-border font-bold text-base" [ngClass]="{'bg-tablePrimary text-white rounded-bl-lg': report.data.length  === (i+1)}" rowspan="5">{{item.rowName}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{report.year}}實際</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.mar | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.apr | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.may | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.june | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.july | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.aug| number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.sep | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.oct | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.nov | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.dec | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.jan | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currSalesAmount.feb | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right text-white" [ngClass]="{'bg-tableSecondary': report.data.length  !== (i+1),'bg-tablePrimary': report.data.length  === (i+1) }">{{ item.currSalesAmount.cur | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border text-right text-white bg-tablePrimary">{{ item.currSalesAmount.sum | number:'1.0-0' }}</td>
            </tr>
            <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white' : ((i+1) % 2) !== 0}">
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{report.year - 1}}實際</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.mar | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.apr| number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.may | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.june  | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.july | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.aug | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.sep  | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.oct | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.nov  | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.dec  | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.jan | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.preSalesAmount.feb | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right text-white" [ngClass]="{'bg-tableSecondary': report.data.length  !== (i+1),'bg-tablePrimary': report.data.length  === (i+1) }">{{ item.preSalesAmount.cur | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border text-right text-white bg-tablePrimary">{{ item.preSalesAmount.sum  | number:'1.0-0' }}</td>
            </tr>
            <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white' : ((i+1) % 2) !== 0}">
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">成長率</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.mar | customPercent }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.apr | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.may | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.june  | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.july  | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.aug | customPercent }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.sep | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.oct | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.nov  | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.dec | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.growthRate.jan | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}"> {{ item.growthRate.feb | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right text-white" [ngClass]="{'bg-tableSecondary': report.data.length  !== (i+1),'bg-tablePrimary': report.data.length  === (i+1) }"> {{ item.growthRate.cur | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border text-right text-white bg-tablePrimary">{{ item.growthRate.sum  | customPercent}}</td>
            </tr>
            <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white' : ((i+1) % 2) !== 0}">
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{report.year}}預算</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.mar | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.apr | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.may | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.june | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.july | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.aug | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.sep | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.oct | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.nov | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.dec  | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.jan | number:'1.0-0' }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.currBudgetAmount.feb | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right text-white" [ngClass]="{'bg-tableSecondary': report.data.length  !== (i+1),'bg-tablePrimary': report.data.length  === (i+1) }">{{ item.currBudgetAmount.cur | number:'1.0-0'}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border text-right bg-tablePrimary text-white" >{{ item.currBudgetAmount.sum ? item.currBudgetAmount.sum : 0 | customPercent }}</td>
            </tr>
            <tr [ngClass]="{'bg-tableLighter': ((i+1) % 2) === 0, 'bg-white' : ((i+1) % 2) !== 0}" class="border-b-2 border-tableBorderColor">
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">達成率</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.mar  | customPercent }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.apr | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.may  | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.june | customPercent }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.july| customPercent }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.aug | customPercent }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.sep | customPercent }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.oct | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.nov  | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.dec  | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.jan | customPercent }}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right" [ngClass]="{'bg-tablePrimary text-white': report.data.length  === (i+1)}">{{ item.progressRate.feb  | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border td-primary-r-border text-right text-white"[ngClass]="{'bg-tableSecondary': report.data.length  !== (i+1),'bg-tablePrimary': report.data.length  === (i+1) }" >{{ item.progressRate.cur  | customPercent}}</td>
              <td class="min-w-32 lg:min-w-24 px-5 py-2.5 lg:py-3  td-primary-b-border text-right  bg-tablePrimary text-white" [ngClass]="{'rounded-br-lg': report.data.length  === (i+1)}">{{ item.progressRate.sum | customPercent}}</td>
            </tr>
          }
          <tr>
        </tbody>
      </table>
    </div>
  </div>

}
