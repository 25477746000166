@if(reportType) {
  <div class="mx-3 xl:ml-0 mb-3">
    <div class="bg-white rounded-lg p-2 lg:p-4 xl:p-4 mb-2">
      <div class="box-border w-full py-1">
        <app-input-filters [configs]="inputs" [loading]="loading" (exportFormValues)="updateSelectedDate($event)"
          class="w-full"></app-input-filters>
      </div>
      <div class="box-border w-full">
        <app-multi-select-filters [data]="singleDropdownSelectedValue" [loading]="loading"  (updateSelected)="updateSelectedType($event)"
        class="w-full"></app-multi-select-filters>
      </div>
    </div>
    @switch (reportType) {
    @case ('products') {
      @if(!loading){
        <app-single-revenuegrowthrate-table [report]="report"  ></app-single-revenuegrowthrate-table>
      }@else {
        <app-loading-spinner></app-loading-spinner>
      }
    }
    @case ('clients') {
      @if(!loading){
        <app-single-revenuegrowthrate-table [report]="report" ></app-single-revenuegrowthrate-table>
      }@else {
        <app-loading-spinner></app-loading-spinner>
      }
    }

    }

  </div>
}
