import { SharedService } from './../../services/shared.service';
import { Component } from '@angular/core';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { UserComponent } from '../../../app/core/user/user.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [BreadcrumbComponent, UserComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  constructor(
    private sharedService: SharedService
  ) {

  }

  toggleSidebar() {
    this.sharedService.toggleSidebar();
  }
}
