import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuItem } from './sidbar.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../../../app/core/services/auth.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  menu: MenuItem[];

  @ViewChildren('navlink', { read: ElementRef }) navLinks: QueryList<ElementRef>;

  constructor(
    private sanitizer: DomSanitizer,
    private sharedservice: SharedService,
    private authenticationService: AuthenticationService
  ) {
    const originMenu: MenuItem [] = [
      {
        name: '業績總表',
        icon: this.sanitizeSvg(`<svg class="h-5 w-5 xl:h-[1.3rem] xl:w-[1.3rem]" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/></svg>`),
        link: 'sales',
        type: 'view',
        id: 1
      },
      {
        name: 'YOY報表',
        icon: this.sanitizeSvg(` <svg class="h-5 w-5 xl:h-[1.3rem] xl:w-[1.3rem]"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"/>
          </svg>`),
        id: 2,
        active: false,
        type: 'view',
        children: [
          {
            name: '產品',
            icon: '',
            link: 'revenueGrowthRate/products',
            id: 3
          },
          {
            name: '客戶',
            icon: '',
            link: 'revenueGrowthRate/clients',
            id: 4
          },
          {
            name: '產品/客戶',
            icon: '',
            link: 'revenueGrowthRate/products-clients',
            id: 5
          },
        ]
      },
      {
        name: '資料匯入',
        icon: this.sanitizeSvg(`<svg class="h-5 w-5 xl:h-[1.3rem] xl:w-[1.3rem]"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2.5"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  <polyline points="7 10 12 15 17 10" />  <line x1="12" y1="15" x2="12" y2="3" /></svg>`),
        link: 'import',
        type: 'operate',
        id: 6
      },
      {
        name: '資料匯出',
        icon: this.sanitizeSvg(`<svg class="h-5 w-5 xl:h-[1.3rem] xl:w-[1.3rem]"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2.5"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  <polyline points="17 8 12 3 7 8" />  <line x1="12" y1="3" x2="12" y2="15" /></svg>`),
        link: 'export',
        type: 'operate',
        id: 7
      },
    ];

    this.menu = originMenu;
    if (this.authenticationService.isAuthToViewReport() && !this.authenticationService.isAuthToUseData()) {
      this.menu = originMenu.filter(item => item.type === 'view');
    } else if (!this.authenticationService.isAuthToViewReport() && this.authenticationService.isAuthToUseData()) {
      this.menu = originMenu.filter(item => item.type === 'operate');
    }


  }

  ngOnInit() {


  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkActiveLinks();
    }, 1);
  }

  // 點擊當前活動選項
  private checkActiveLinks() {
    this.navLinks.forEach(link => {
      if (link.nativeElement.classList.contains('text-white')) {
        this.triggerClick(link.nativeElement);
      }
    });
  }

  toggleSidebar() {
    if (window.innerWidth < 1024) {
      this.sharedservice.toggleSidebar();
    }
  }
  // 觸發當前選項
  triggerClick(element: HTMLElement) {
    element.click();
  }

  // 渲染SVG
  sanitizeSvg(svg: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  // 展開子菜單
  toggleSubMenu(item: MenuItem): void {
    item.active = !item.active;
  }

  // 更新菜單當前選中項目

  updateActiveItem(item: MenuItem, subitem?: MenuItem): void {
    this.menu.forEach(menuItem => {
      if (menuItem === item) {
        menuItem.active = true;
        if (subitem) {
          menuItem.children?.forEach(sub => sub.active = sub === subitem);
        } else {
          menuItem.children?.forEach(sub => sub.active = false);
        }
      } else {
        menuItem.active = false;
        menuItem.children?.forEach(sub => sub.active = false);
      }
    });

    this.toggleSidebar();
    this.sharedservice.setCurrentRouteItem(item, subitem?.name ? subitem.name : '');
  }


}
