<div class="flex mt-4 mb-1 mx-3 xl:ml-0">
<div  class="basis-1/2 self-center">
  <span (click)="toggleSidebar()">
    <button class="navbar-burger items-center">
      <svg  class="h-4 w-4 text-inherit"   aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
      </svg>
    </button>
  </span>
</div>
  <div class="basis-1/2 self-center flex items-center justify-end">
    <app-user class="inline-block"></app-user>
  </div>
</div>
