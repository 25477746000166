import { Component } from '@angular/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { HeaderComponent } from '../header/header.component';
import { RouterOutlet } from '@angular/router';
import { InputFiltersComponent } from '../input-filters/input-filters.component';
import { SharedService } from '../../services/shared.service';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [SidebarComponent, RouterOutlet, HeaderComponent, InputFiltersComponent, CommonModule, BreadcrumbComponent],
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent {

  sidebarVisible = false;
  constructor(
    private sharedService: SharedService
  ) {
    this.sharedService.sidebarVisible$.subscribe(visible => {
      this.sidebarVisible = visible;
      console.log(this.sidebarVisible);
    });
  }

}
