import { Routes, mapToCanActivate } from '@angular/router';
import { MainLayoutComponent } from '../shared/components/main-layout/main-layout.component';
import { SalesDisplayComponent } from './feature/reports/components/sales-display/sales-display.component';
import { ImportComponent } from './feature/reports/components/import/import.component';
import { ExportComponent } from './feature/reports/components/export/export.component';
import { RevenuegrowthrateDisplayComponent } from './feature/reports/components/revenuegrowthrate-display/revenuegrowthrate-display.component';
import { MultiRevenugrowthrateDisplayComponent } from './feature/reports/components/multi-revenugrowthrate-display/multi-revenugrowthrate-display.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'sales',
        component: SalesDisplayComponent
      },
      {
        path:'import',
        component: ImportComponent
      },
      {
        path: 'export',
        component: ExportComponent
      },
      {
        path: 'revenueGrowthRate/products-clients',
        component: MultiRevenugrowthrateDisplayComponent
      },
      {
        path: 'revenueGrowthRate/:type',
        component: RevenuegrowthrateDisplayComponent
      }
    ]
  },

];
