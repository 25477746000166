import { InputFiltersComponent } from '../../../../../shared/components/input-filters/input-filters.component';
import { FilterConfig } from '../../../../../shared/shared.models';
import { PlaceGroupsWithPlaces, Products} from '../../report.models';
import { ReportService } from '../../services/report.service';
import { MultiSelectFiltersComponent } from './../../../../../shared/components/multi-select-filters/multi-select-filters.component';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-export',
  standalone: true,
  imports: [MultiSelectFiltersComponent, InputFiltersComponent, ReactiveFormsModule],
  templateUrl: './export.component.html',
  styleUrl: './export.component.scss'
})
export class ExportComponent {

  clients: PlaceGroupsWithPlaces[];
  selectedClients: FilterConfig;
  // products: Products[];
  // selectedProducts: FilterConfig;
  filterForm: FormGroup;
  selectedSingleInputs: FilterConfig[];

  constructor(
    private reportService: ReportService,
    private fb: FormBuilder
  ) {
    this.filterForm = this.fb.group({});
    this.setOptions();
  }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      fromDate: new FormControl(null, Validators.required),
      toDate: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      placeGroupCodes: new FormControl(null),
      productGroupCodes: new FormControl(null)
    }, { validators: this.atLeastOne(['placeGroupCodes', 'productGroupCodes']) });
  }

  //設定表單欄位
  setOptions() {
    //取得客戶(類別)資料 傳遞給multi-select-filters
    this.reportService.getCustomersGroupDetails(true)
      .subscribe((data) => {
        this.clients = data;
        this.clients.forEach((item) => {
          item.placeVMs.forEach((place) => {
            place.isSelected = false;
           });
          item.placeVMs.unshift({ placeCode: 'ALL', placeName: '全部', placeGroupName: item.placeGroupName, isSelected: false })
        });
        this.selectedClients = { key: 'clients', type: 'multi-select', label: '客戶', options: this.clients }
      });

    //取得產品(類別)資料 傳遞給multi-select-filters
    this.reportService.getProductCategories(true)
      // .subscribe((data) => {
      //   data.forEach((product) => {
      //     product.isSelected = true;
      //   });
      //   this.products = data;
      //   this.products.unshift({ code: 'ALL', name: '全部', isSelected: true });
      //   this.selectedProducts = { key: 'products', type: 'multi-select', label: '產品', options: this.products }
      // });
    this.selectedSingleInputs = [
      { key: 'fromDate', type: 'date', label: '起始日期' },
      { key: 'toDate', type: 'date', label: '結束日期' },
      { key: 'type', type: 'select-single', label: '類型', options: [{ name: 'SAP業績', value: 'Sap' }, { name: '通路業績', value: 'Sale' }] }
    ];
  }

  //取得客戶資料
  updateClientSelected(item: PlaceGroupsWithPlaces[]) {
    this.filterForm.patchValue({
      placeGroupCodes: this.filterSelectedClients(item)
    });
    this.filterForm.updateValueAndValidity();
  }

  //取得產品資料
  // updateProductsSelected(item: { code: string, name: string, isSelected: boolean }[]) {
  //   this.filterForm.patchValue({
  //     productGroupCodes: this.filterSelectedProducts(item)
  //   });
  //   this.filterForm.updateValueAndValidity();
  // }

  //取得日期資料
  updateDateSelected(item: { fromDate: string, toDate: string, type: string }) {
    this.filterForm.patchValue({
      fromDate: item.fromDate,
      toDate: item.toDate,
      type: item.type
    });
    this.filterForm.updateValueAndValidity();

  }

  //篩選選取客戶
  filterSelectedClients(item: PlaceGroupsWithPlaces[]): string[] {
    const selectedPlaces: string[] = [];
    item.forEach((group) => {
      if (group.placeVMs.some((place) => place.placeCode === 'ALL' && place.isSelected)) {
        selectedPlaces.push(...group.placeVMs.map((place) => place.placeCode));
      } else {
        selectedPlaces.push(...group.placeVMs.filter((place) => place.isSelected).map((place) => place.placeCode));
      }
    });
    return selectedPlaces;
  }

  //篩選選取產品
  filterSelectedProducts(item: { code: string, name: string, isSelected: boolean }[]): string[] {
    const selectedProducts: string[] = [];
    item.forEach((product) => {
      if (product.code === 'ALL' && product.isSelected) {
        selectedProducts.push(...item.map(product => product.code));
      } else if (product.isSelected) {
        selectedProducts.push(...item.filter(product => product.isSelected).map(product => product.code));
      }
    });
    return selectedProducts;
  }

  //匯出報表
  exportReport() {
    this.reportService.exportReport(this.filterForm.value);
  }

  atLeastOne(controlsNames: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      let hasValue = false;

      for (const name of controlsNames) {
        const fieldValue = formGroup.get(name)?.value;
        if (fieldValue !== null && fieldValue !== undefined && fieldValue !== '' && (!Array.isArray(fieldValue) || fieldValue.length > 0)) {
          hasValue = true;
        }
      }

      return hasValue ? null : { atLeastOne: 'At least one field must be filled.' };
    };
  }
}

