import { Component } from '@angular/core';
import { MultiSelectFiltersComponent } from '../../../../../shared/components/multi-select-filters/multi-select-filters.component';
import { InputFiltersComponent } from '../../../../../shared/components/input-filters/input-filters.component';
import { LoadingSpinnerComponent } from '../../../../../shared/components/loading-spinner/loading-spinner.component';
import { MultiRevenuegrowthTableComponent } from '../multi-revenuegrowth-table/multi-revenuegrowth-table.component';
import { FilterConfig, FilterConfigOptions } from '../../../../../shared/shared.models';
import { ReportService } from '../../services/report.service';
import { map, range } from 'rxjs';
import { BaseProductGroups, PlaceGroupsWithPlaces, PlaceGroupsWithRevenueGrowth, Products, ReportFilter } from '../../report.models';

@Component({
  selector: 'app-multi-revenugrowthrate-display',
  standalone: true,
  imports: [MultiSelectFiltersComponent, MultiRevenuegrowthTableComponent, InputFiltersComponent, LoadingSpinnerComponent],
  templateUrl: './multi-revenugrowthrate-display.component.html',
  styleUrl: './multi-revenugrowthrate-display.component.scss'
})

export class MultiRevenugrowthrateDisplayComponent {

  productSelectedSetting: FilterConfig;
  productSelectedValue: FilterConfig;
  clientSelectedSetting: FilterConfig;
  clientSelectedValue: FilterConfig;
  dateSelectedSetting: FilterConfig[];

  years: FilterConfigOptions[];
  filter: ReportFilter = { fromYear: new Date().getFullYear().toString() };
  clients: PlaceGroupsWithPlaces[];
  products: Products[];
  rowData: PlaceGroupsWithRevenueGrowth[];
  tableData: any[];
  productsHeaders: BaseProductGroups[];
  loading: boolean = false;

  constructor(
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.setOptions();
    this.setClients();
    this.setProducts();
  }

  //設定表單欄位
  setOptions() {
    this.reportService.getYears()
      .pipe(
        map(y => y.map(y => ({ value: y })))
      )
      .subscribe(years => {
        this.years = years;
        this.dateSelectedSetting = [
          { key: 'year', type: 'select-single', label: '年度', options: this.years, defualtValue: this.filter.fromYear }
        ];
      });
  }

  //取得客戶(類別)資料 傳遞給multi-select-filters
  setClients() {
    this.reportService.getCustomersGroupDetails(false)
      .subscribe(async (data) => {
        this.clients = data;
        this.clients.forEach((item) => {
          item.placeVMs.forEach((place) => {
            place.isSelected = true;
           });
          item.placeVMs.unshift({ placeCode: 'ALL', placeName: '全部', placeGroupName: item.placeGroupName, isSelected: true })
        });
        this.clientSelectedValue = { key: 'clients', type: 'multi-select', label: '客戶', options: this.clients }
        this.filter.placeGroupCodes = [];
      });
  }

  // 取得產品(類別)資料 傳遞給multi-select-filters
  setProducts() {
    this.reportService.getProductCategories(false)
      .subscribe((data) => {
        data.forEach((product) => {
          product.isSelected = true;
        });
        this.products = data;
        this.productsHeaders = this.products.map(product => ({ productGroupName: product.name, productGroupCode: product.code }))
        .concat({ productGroupName: '總計', productGroupCode: 'SUM' });
        this.products.unshift({ code: 'ALL', name: '全部', isSelected: true });
        this.productSelectedValue = { key: 'products', type: 'multi-select', label: '產品', options: this.products };
      });
  }

  //轉換表格資料
  convertTableData(): void {
    console.log(this.rowData);
    this.tableData = this.rowData.map(place => {
      const productsData = this.productsHeaders.map(product => {
        const data = place.datas.find(d => d.productGroupCode === product.productGroupCode);
        return {
          productCode: product.productGroupCode,
          productName: data?.placeGroupName ?? '',
          currSalesAmount: data?.currSalesAmount,
          preSalesAmount: data?.preSalesAmount,
          growthRate: data?.growthRate,
          rangePreSalesAmount: data?.rangePreSalesAmount
        };
      });

      return {
        placeName: place.placeGroupName,
        productsData: productsData,
        rowspan: productsData.length,
        year: Number(this.filter.fromYear)
      };
    });
    console.log(this.tableData);
    this.loading = false;
  }

  //監聽日期更新
  updateDateSelected(item: any) {
    this.filter.fromYear = item.year;
    if(item) {
      this.getYOYReport();
    }
  }

  //監聽客戶更新
  updateClientSelected(item: any) {
    this.filter.placeGroupCodes = this.filterSelectedClients(item);
    if(item) {
      this.getYOYReport();
    }
  }

  //監聽產品更新
  updateProductSelected(item: any) {
    this.filter.productGroupCodes = this.filterSelectedProducts(item);
    if(item) {
      this.getYOYReport();
    }
  }

  //篩選選取客戶
  filterSelectedClients(item: PlaceGroupsWithPlaces[]): string[] {
    const selectedPlaces: string[] = [];
    item.forEach((group) => {
      if (group.placeVMs.some((place) => place.placeCode === 'ALL' && place.isSelected)) {
        selectedPlaces.push(...group.placeVMs.map((place) => place.placeCode));
      } else {
        selectedPlaces.push(...group.placeVMs.filter((place) => place.isSelected).map((place) => place.placeCode));
      }
    });
    return selectedPlaces;
  }

  //篩選選取產品
  filterSelectedProducts(item: { code: string, name: string, isSelected: boolean }[]): string[] {
    const selectedProducts: string[] = [];
    item.forEach((product) => {
      if (product.code === 'ALL' && product.isSelected) {
        selectedProducts.push(...item.map(product => product.code));
      } else if (product.isSelected) {
        selectedProducts.push(...item.filter(product => product.isSelected).map(product => product.code));
      }
    });
    return selectedProducts;
  }

  // 取得YOY報表API
  async getYOYReport() {
    setTimeout(() => {
      this.loading = true;
    }, 50);
    if(!this.filter.productGroupCodes || !this.filter.placeGroupCodes) {
      return;
    }
    this.rowData = await this.reportService.takeProductAndClientsRevenueGrowth(this.filter);
    this.convertTableData();
  }
}
