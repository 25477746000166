<div class="flex" >
  <app-sidebar
    [ngClass]="{'fixed top-0 left-0 bottom-0  lg:hidden': sidebarVisible, 'relative hidden lg:block': !sidebarVisible}"
    class="bg-[#222] text-white  transition-all duration-300 ease-in-out z-50"
    style="min-width: 230px; min-height: 100vh;">
  </app-sidebar>
  <div class="relative pl-0 lg:pl-4" style="width: 100%; overflow-x: auto;">
    <app-header></app-header>
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
  </div>
</div>
