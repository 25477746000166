import { SafeHtml } from "@angular/platform-browser";

/**
 * 左側選單項目
 *
 * @export
 * @class MenuItem
 */
export class MenuItem {
  id: number;
  name: string;
  icon: SafeHtml;
  link?: string;
  children?: MenuItem[];
  active?: boolean;
  type?: string;
}
