<div class="mx-3 xl:ml-0 mb-3">
  <div class="bg-white rounded-lg p-2 lg:p-4 xl:p-4">
    <div class="bbox-border w-full py-1">
      <app-input-filters [configs]="dateSelectedSetting" [loading]="loading" (exportFormValues)="updateDateSelected($event)"
        class="w-full"></app-input-filters>
    </div>
    <div class="box-border w-full">
      <app-multi-select-filters [data]="clientSelectedValue" [loading]="loading" (updateSelected)="updateClientSelected($event)" class="w-full" [loading]="loading"></app-multi-select-filters>
      <app-multi-select-filters [data]="productSelectedValue" [loading]="loading" (updateSelected)="updateProductSelected($event)" class="w-full" [loading]="loading"></app-multi-select-filters>
    </div>
  </div>

  @if(!loading) {
    <app-multi-revenuegrowth-table [tableData]="tableData" [header]="productsHeaders"></app-multi-revenuegrowth-table>
  }@else {
    <app-loading-spinner></app-loading-spinner>
  }


</div>
