import { Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { FilterConfig, FilterConfigOptions } from '../../../../../shared/shared.models';
import { BehaviorSubject, forkJoin, map } from 'rxjs';
import { ReportService } from '../../services/report.service';
import { InputFiltersComponent } from '../../../../../shared/components/input-filters/input-filters.component';
import { PerformanceGroup, ReportFilter, PlaceGroupsWithPlaces, DateRangeCalculator} from '../../report.models';
import { MultiSelectFiltersComponent } from '../../../../../shared/components/multi-select-filters/multi-select-filters.component';
import { EmailComponent } from '../../email/email.component';
import { SalesTableComponent } from '../sales-table/sales-table.component';
import { LoadingSpinnerComponent } from '../../../../../shared/components/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-sales-display',
  standalone: true,
  imports: [InputFiltersComponent, MultiSelectFiltersComponent, SalesTableComponent, EmailComponent, LoadingSpinnerComponent],
  templateUrl: './sales-display.component.html'
})
export class SalesDisplayComponent {

  loading: boolean = true;
  inputs: FilterConfig[];
  years: FilterConfigOptions[];
  months: FilterConfigOptions[];

  clients: PlaceGroupsWithPlaces[];
  selectedClients: FilterConfig;

  selectedPerformanceDate: ReportFilter = new ReportFilter();
  currentPerformance: PerformanceGroup[] = [];
  currentPerformance$: BehaviorSubject<PerformanceGroup[]> = new BehaviorSubject<PerformanceGroup[]>([]);

  currentYearPermanceDate: ReportFilter;
  currentYearPerformance: PerformanceGroup[] = [];

  preYearPermanceDate: ReportFilter;
  preYearPerformance: PerformanceGroup[] = [];

  @ViewChild('topScrollbar') topScrollbar: ElementRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(
    private reportService: ReportService,
    private renderer: Renderer2
  ) {

    //取得客戶(類別)資料 傳遞給multi-select-filters
    this.reportService.getCustomersGroupDetails(false)
    .subscribe((data) => {
      data.forEach((item) => {
        item.placeVMs.forEach((place) => {
          place.isSelected = true;
        });
        item.placeVMs.unshift({ placeCode: 'ALL', placeName: '全部', placeGroupName: item.placeGroupName, isSelected: true });

      });

      this.clients = data;
      this.selectedClients = { key: 'clients', type: 'multi-select', label: '客戶', options: this.clients}
    });

    // this.setOptions();

    //訂閱業績資料
    this.currentPerformance$.subscribe(data => {
      this.currentPerformance = data;
    });
  }


  async ngOnInit() {
    this.getSelectedPerformaceDate();
  }

  ngAfterViewInit(): void {
    const topScrollbar = this.topScrollbar.nativeElement;
    const scrollContainer = this.scrollContainer.nativeElement;
    const tableDynamic = scrollContainer.querySelector('.table-dynamic');

    const syncScroll = (source: any, targets: any) => {
      source.addEventListener('scroll', () => {
        targets.forEach((target: any) => {
          if (target.scrollLeft !== source.scrollLeft) {
            target.scrollLeft = source.scrollLeft;
          }
        });
      });
    };

    syncScroll(topScrollbar, [scrollContainer]);
    syncScroll(scrollContainer, [topScrollbar]);

    const scrollbarInnerElements = document.querySelectorAll('.scrollbar-inner');
    scrollbarInnerElements.forEach(inner => {
      const element = inner as HTMLElement;
      element.style.width = `${scrollContainer.scrollWidth}px`;
    });

    const checkOverflow = () => {
      const isOverflowing = scrollContainer.scrollWidth > scrollContainer.clientWidth;
      topScrollbar.parentElement.style.display = isOverflowing ? 'block' : 'none';
      if (isOverflowing) {
        this.renderer.addClass(tableDynamic, 'overflowing');
      } else {
        this.renderer.removeClass(tableDynamic, 'overflowing');
      }
    };

    window.addEventListener('resize', checkOverflow);


    checkOverflow();
  }

  //取得篩選日期
  getSelectedPerformaceDate(item?: ReportFilter) {
    this.loading = true;
    this.selectedPerformanceDate.fromYear = item?.fromYear ? item.fromYear : new Date().getFullYear().toString();
    this.selectedPerformanceDate.fromMonth = item?.fromMonth ? item.fromMonth :(new Date().getMonth() + 1).toString().padStart(2, '0');
    this.selectedPerformanceDate.toYear = item?.toYear ? item.fromYear : new Date().getFullYear().toString();
    this.selectedPerformanceDate.toMonth =  item?.toMonth ? item.toMonth : (new Date().getMonth() + 1).toString().padStart(2, '0');
    let calculator = new DateRangeCalculator(Number(this.selectedPerformanceDate.fromYear), Number(this.selectedPerformanceDate.fromMonth));
    this.currentYearPermanceDate = calculator.getFinancialYearRange();
    this.preYearPermanceDate = calculator.getPreviousFinancialYearRange();
    this.setOptions();
  }

  //取得業績API
  getPerformance(item: ReportFilter): Promise<PerformanceGroup[]>{
    return this.reportService.takeSalePerformanceData(item);
  }

  //取得業績
 async setPerformance(){

    const totalPerformance = await this.getPerformance(this.selectedPerformanceDate);
    this.currentPerformance = this.filterPerformanceGroups(totalPerformance, this.clients);

    const currentYearPerformance = await this.getPerformance(this.currentYearPermanceDate);
    this.currentYearPerformance = this.filterPerformanceGroups(currentYearPerformance, this.clients);

    const preYearPerformance = await this.getPerformance(this.preYearPermanceDate);
    this.preYearPerformance = this.filterPerformanceGroups(preYearPerformance, this.clients);
    this.loading = false;
  }

  //設定日期選項傳入input-filters
  setOptions() {
    forkJoin({
      years: this.reportService.getYears().pipe(
        map(y => y.map(y => ({ value: y })))),
      months: this.reportService.getMonths().pipe(
        map( m => m.map( m => ({ value: m })))),
    })
    .subscribe(({ years, months}) => {
      this.years = years || [];
      this.months = months || [];
      this.inputs = [
        { key: 'year', type: 'select-double', label: '日期(年)', options: this.years, defualtValue: this.selectedPerformanceDate.fromYear},
        { key: 'month', type: 'select-double', label: '日期(月)', options: this.months, defualtValue: this.selectedPerformanceDate.fromMonth},
      ];
    });
  }

  //更新篩選日期
  async updateSelectedDate(item: { year: string, month: string }) {
    this.getSelectedPerformaceDate({ fromYear: item.year, fromMonth: item.month, toYear: item.year, toMonth: item.month });
    if (item.year && item.month) {
      this.setPerformance();
    }
  }

  //更新客戶
  async updateSelectedClient(item: any){
    const data = this.filterPerformanceGroups(this.currentPerformance, item);
    this.reportService.updatePerformance(data);
  }

  // 篩選選取客戶
  filterPerformanceGroups(performanceGroups: PerformanceGroup[], placeGroups: PlaceGroupsWithPlaces[]): PerformanceGroup[] {
    const placeSelectionMap: Record<string, boolean> = {};
    placeGroups?.forEach(group => {
      if(group.placeVMs) {

        group.placeVMs.forEach(place => {
          placeSelectionMap[place.placeCode] = place.isSelected ?? false;
        });

        let allData = group.placeVMs.find(item => item.placeCode === 'ALL');
        if (allData?.active) {
          group.placeVMs.forEach(place => {
            placeSelectionMap[place.placeCode] = allData.isSelected ?? false;
          });
        } else {
          const otherData = group.placeVMs.filter(item => item.placeCode !== 'ALL');
          if (otherData.every(item => item.isSelected)) {
            if (allData) {
              allData.isSelected = true;
              placeSelectionMap['ALL'] = allData.isSelected;
            }
          }else {
            if (allData) {
              allData.isSelected = false;
              placeSelectionMap['ALL'] = allData.isSelected;
            }
          }
        }
      }
    });


    performanceGroups.forEach(group => {
      group.details.forEach(detail => {
        if (placeSelectionMap.hasOwnProperty(detail.placeGroupCode ?? '')) {
          detail.isSelected = placeSelectionMap[detail.placeGroupCode ?? ''];
        }
      });
    });

    return performanceGroups;
  }

  //更新業績子選項toggle狀態
  toogleSubPerformane(item: PerformanceGroup) {
    console.log(item);
    console.log(this.currentPerformance);
    const mapDepartment  = this.currentPerformance.find( group => group.department === item.department);
    console.log(mapDepartment );
    if (mapDepartment) {
      mapDepartment.performance.isToggle = item.performance.isToggle;
    }
    console.log(this.currentPerformance);
  }

}
