import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MultiSelectFiltersComponent } from '../multi-select-filters/multi-select-filters.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FilterConfig } from '../../shared.models';
import { CommonModule } from '@angular/common';
import { distinctUntilChanged } from 'rxjs';
@Component({
  selector: 'app-input-filters',
  standalone: true,
  imports: [MultiSelectFiltersComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './input-filters.component.html'
})
export class InputFiltersComponent {
  @Input() configs: FilterConfig[] = [];
  @Output() exportFormValues = new EventEmitter();
  @Input() loading: boolean;

  filterForm: FormGroup;
  defaultDate = new Date().toISOString().substring(0, 10);

  constructor(private fb: FormBuilder) {
    this.filterForm = this.fb.group({});
    this.filterForm.valueChanges.pipe(
      distinctUntilChanged((prev, curr) => {
        return JSON.stringify(prev) === JSON.stringify(curr);
      })
    ).subscribe(value => {
      this.exportFormValues.emit(value);
    });
  }

  ngOnInit(): void {
    // this.initializeForm();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loading'] && !changes['loading'].firstChange) {
      this.updateFormState();
    }

    if (changes['configs'] && changes['configs'].currentValue) {
      this.initializeForm();
    }
  }

  resetForm() {
    this.filterForm = this.fb.group({});
  }

  // 更新表單狀態
  updateFormState() {
    if (this.loading && this.filterForm.enabled) {
      this.filterForm.disable();
    } else if (!this.loading && this.filterForm.disabled) {
      this.filterForm.enable();
    }
  }

  // 初始化表單
  initializeForm() {
    this.configs.forEach(config => {
      if (!this.filterForm.contains(config.key)) {
        this.filterForm.addControl(
          config.key,
          this.fb.control(config.defualtValue || '', Validators.required)
        );
      }
      if (config.type === 'date' && !this.filterForm.get(config.key)?.value) {
        this.filterForm.get(config.key)?.setValue(this.defaultDate);
      }
    });
  }

}
