import { Component, Input, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import { BaseProductGroups } from '../../report.models';
import { CustomPercentPipe } from '../../../../../shared/pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-multi-revenuegrowth-table',
  standalone: true,
  imports: [CommonModule, CustomPercentPipe],
  templateUrl: './multi-revenuegrowth-table.component.html',
  styleUrl: './multi-revenuegrowth-table.component.scss'
})
export class MultiRevenuegrowthTableComponent {
  @Input() tableData: any[];
  @Input() header: BaseProductGroups[];
  dynamicColHalfWidth: string;
  dynamicColWidth: string;
  dynamicColDoubleWidth: string;

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes['tableData'].currentValue);
    if (changes['tableData'] && changes['tableData'].currentValue) {
      const numberOfColumns = 12;
      const columnWidth = 100 / numberOfColumns;
      this.dynamicColWidth = `${columnWidth}%`;
      this.dynamicColHalfWidth = `${columnWidth / 2}%`;
      this.dynamicColDoubleWidth = `${columnWidth * 2}%`;
    }
  }

  ngAfterViewInit(): void {

  }
}
