import { Component } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [],
  template: `<h1 class="font-bold text-[1.5em] mb-2 mx-2">{{activeTitle}}</h1>`
})
export class BreadcrumbComponent {
  activeTitle: string = '';
  mainTitle: string = '';

  constructor(
    private sharedservice: SharedService
  ) {

  }
  ngOnInit(): void {
    this.sharedservice.currentMainMenuItem.subscribe(item => {
      this.mainTitle = item.name;
    });
    this.sharedservice.currentMenuItemName.subscribe(subitem => {
      this.activeTitle = subitem ?  subitem  : this.mainTitle;
    });
  }
}
