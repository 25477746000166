<div class="mx-3 xl:ml-0">
  <div class=" bg-white rounded-lg p-2 lg:p-4 xl:p-4">
    <app-input-filters [configs]="inputs" (exportFormValues)="getForm($event)"></app-input-filters>
    <div class="p-2 flex">
      <input [(ngModel)]="file" (change)="uploadFile($event)" accept=".xls,.xlsx" class="block w-1/3 p-1 text-sm text-activeColor  rounded-lg cursor-pointer"
        id="multiple_files" type="file" multiple>
        @if (fileData && formData.type === 'BudgetData') {
          <button (click)="toggleModal()" class="bg-primaryColor text-sm hover:bg-primaryHoverColor text-white py-1 px-4 ml-2 rounded">
            確認
          </button>
        } @else if(fileData && formData.type === 'SalesData') {
          <button (click)="importFile()" class="bg-primaryColor text-sm hover:bg-primaryHoverColor text-white py-1 px-4 ml-2 rounded">
            確認
          </button>
        }
    </div>
  </div>


<!-- 預算紀錄Modal -->
 @if (versions) {
  <app-modal [isVisiable]="showModal" [title]="modalTitle">
    <div class="p-6 space-y-6">
      <ul>
        @if (versions.length === 0 ) {
          <li class="text-gray-400 text-sm">尚未有版本</li>
        } @else {
          @for(budget of versions; track $index) {
            <li>
              <strong>{{budget.version}}</strong>
              <strong>{{budget.updateUser}}</strong>
              於{{budget.updateDatetime}}上傳
            </li>
            }
        }
      </ul>
    </div>
      <!-- Modal footer -->
      <div class="flex justify-center items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
        <button data-modal-hide="static-modal" type="button" (click)="importFile()"
          class="bg-primaryColor text-sm hover:bg-primaryHoverColor text-white py-2 px-4 ml-2 rounded">
          匯入</button>
        <button data-modal-hide="static-modal" type="button" (click)="toggleModal()"
          class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100">取消</button>
      </div>
  </app-modal>

 }

</div>
