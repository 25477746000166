import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleError(error);
        return throwError(() => new Error(error.statusText));
      })
    );
  }

  private handleError(error: HttpErrorResponse): void {
    switch (error.status) {
      case 400:
        this.toastr.error('錯誤的請求。請檢查您的請求後重試。', '400 錯誤的請求');
        break;
      case 401:
        this.toastr.error('未授權。請重新登錄。', '401 未授權');
        break;
      case 403:
        this.toastr.error('拒絕訪問。您無權訪問所請求的資源。', '403 禁止訪問');
        break;
      case 404:
        this.toastr.error('未找到請求的資源，請確認網址是否正確', '404 資源未找到');
        break;
      case 500:
        this.toastr.error('內部服務器錯誤。請稍後再試。', '500 服務器錯誤');
        break;
      case 502:
        this.toastr.error('錯誤的網關。服務器收到無效響應。', '502 錯誤的網關');
        break;
      case 503:
        this.toastr.error('服務不可用。請稍後再試。', '503 服務不可用');
        break;
      case 504:
        this.toastr.error('網關超時。服務器未及時響應。', '504 網關超時');
        break;
      default:
        this.toastr.error(error.message || '發生未知錯誤。請再試一次。', `錯誤 ${error.status}`);
        break;
    }
  }
}
